module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-142042694-3","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","name":"ズルズル","start_url":"/","theme_color":"#698474","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"398798ffa039454bb7130d89d555947a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/Users/yagijin/src/github.com/yagijin/zuruzuru/node_modules/gatsby-remark-numbered-footnotes","id":"4c0909b1-1583-5ca8-8913-9ca44e3102d1","name":"gatsby-remark-numbered-footnotes","version":"1.0.1","modulePath":"/Users/yagijin/src/github.com/yagijin/zuruzuru/node_modules/gatsby-remark-numbered-footnotes/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/Users/yagijin/src/github.com/yagijin/zuruzuru/node_modules/gatsby-remark-prismjs","id":"2b069c37-8d3b-5202-a1a4-6f37de46bcc7","name":"gatsby-remark-prismjs","version":"6.20.0","modulePath":"/Users/yagijin/src/github.com/yagijin/zuruzuru/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[],"classPrefix":"language-","inlineCodeMarker":null,"aliases":{"js":"javascript","ts":"typescript","sh":"shell"},"showLineNumbers":false,"noInlineHighlight":false,"prompt":{"user":"root","host":"localhost","global":false},"escapeEntities":{}},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/Users/yagijin/src/github.com/yagijin/zuruzuru/node_modules/gatsby-remark-embedder","id":"a9976fde-dd82-5c8d-a3fe-aa40dcd81014","name":"gatsby-remark-embedder","version":"6.0.1","modulePath":"/Users/yagijin/src/github.com/yagijin/zuruzuru/node_modules/gatsby-remark-embedder/dist/index.js","pluginOptions":{"plugins":[],"customTransformers":[],"services":{}},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/yagijin/src/github.com/yagijin/zuruzuru","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
